import React from "react";
import { SnackbarProvider } from "notistack";
import AppRoutes from "./routes/AppRoutes";

const App = () => {
  return (
    <SnackbarProvider>
      <AppRoutes />
    </SnackbarProvider>
  );
};

export default App;
