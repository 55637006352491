import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import API from "../utils/Axios";
import FlightLayout from "./FlightLayout";
import { flightModes, flightStatus } from "../constants/enums";
import EmptyListItem from "./utils/EmptyListItem";
import { MdOutlineFlight } from "react-icons/md";
import { ScaleLoader } from "react-spinners";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { BiErrorCircle } from "react-icons/bi";
import io from "socket.io-client";
import { environment } from "../config/environment";

const {
  api: { uri },
} = environment;

const socket_url = uri.slice(0, -4);

const socket = io(socket_url);

const Flights = () => {
  const [page] = useState(1);
  const [scrollDirection, setScrollDirection] = useState("up");

  const queryClient = useQueryClient();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const code = queryParams.get("code")?.toUpperCase();

  const fetchSchedules = async () => {
    try {
      const res = await API.get(
        `/schedules/daily-display?page=${page}&limit=100&code=${code}&status=${flightStatus.APPROVED}&mode=${flightModes.BOTH}`
      );

      return res;
    } catch (e) {}
  };

  const { isLoading, isError, data, refetch } = useQuery({
    queryKey: ["flights"],
    queryFn: fetchSchedules,
    // refetchInterval: 30 * 60 * 1000, // Set the refetch interval to 30 minutes (in milliseconds)
  });

  const docs = data?.data?.data?.docs;

  useEffect(() => {
    // Add the socket event listener when the component mounts
    const handleUpdateSchedule = (updatedData) => {
      // Update the data in the React Query cache if the updated event concerns the current station
      if (updatedData?.code?.includes(code)) {
        queryClient.invalidateQueries();
      }
    };

    socket.on("updateSchedule", handleUpdateSchedule);
    socket.on("create-schedule", handleUpdateSchedule);

    // Clean up the event listener when the component unmounts
    return () => {
      socket.off("updateSchedule", handleUpdateSchedule);
    };
  }, [code, queryClient]);

  useEffect(() => {
    // Function to handle scrolling up and down
    const handleAutoScroll = () => {
      const scrollStep = 1; // Number of pixels to scroll per step

      // Get the current scroll position
      let currentPosition = window.scrollY;

      // Calculate the new scroll position based on the direction
      const newPosition = scrollDirection === "up" ? currentPosition - scrollStep : currentPosition + scrollStep;

      // Scroll the page to the new position
      window.scrollTo(0, newPosition);

      // Check if we need to change the scroll direction
      if (newPosition <= 0) {
        setScrollDirection("down");
      } else if (newPosition >= document.body.clientHeight - window.innerHeight) {
        setScrollDirection("up");
      }
    };

    // Start auto-scrolling interval if there's data
    const scrollInterval =
      docs?.arrival?.length > 0 || docs?.departure?.length > 0 ? setInterval(handleAutoScroll, 30) : null;

    // Clean up the interval when the component unmounts
    return () => clearInterval(scrollInterval);
  }, [scrollDirection, docs]);

  return isLoading ? (
    <div className="h-[80vh] flex items-center justify-center">
      <ScaleLoader color="#DF7024" loading={isLoading} height={60} />
    </div>
  ) : (docs?.arrival?.length === 0 && docs?.departure?.length === 0) || isError ? (
    <div className="flex items-center justify-center min-h-[350px] md:min-h-[400px] h-[80vh]">
      <EmptyListItem
        icon={
          isError ? (
            <BiErrorCircle className="text-2xl text-primary" />
          ) : (
            <MdOutlineFlight className="text-2xl text-primary" />
          )
        }
        text1={isError ? "Oops, an error occured. please try again" : "No flight data available to view."}
        button={
          <button
            type="button"
            className="btn btn-primary btn-block xs:btn-wide px-8 text-sm md:text-base normal-case"
            onClick={refetch}
          >
            {isError ? "Retry" : "Reload"}
          </button>
        }
      />
    </div>
  ) : (
    <div className="bg-container py-12 grid md:grid-cols-2 gap-10 md:gap-20">
      <FlightLayout title="Arrivals" data={docs?.arrival} />

      <FlightLayout title="Departures" data={docs?.departure} />
    </div>
  );
};

export default Flights;
