import React from "react";
import PropTypes from "prop-types";

const EmptyListItem = ({ icon, text1, text2, button }) => {
  return (
    <div className="mx-auto flex h-[180px] w-full max-w-[90%] items-center justify-center text-center md:max-w-[80%] 2xl:max-w-[75%]">
      <div className="flex flex-col items-center space-y-5">
        <span className="flex h-14 w-14 items-center justify-center rounded-full bg-[#f9e2d2]">{icon}</span>

        <div>
          <p className="text-lg 2xl:text-xl font-light text-[#5a5a5a]">{text1}</p>
          <p className="text-base font-light text-[#5a5a5a]">{text2}</p>
        </div>

        <>{button && button}</>
      </div>
    </div>
  );
};

export default EmptyListItem;

EmptyListItem.propTypes = {
  icon: PropTypes.node,
  text1: PropTypes.string,
  text2: PropTypes.string,
  button: PropTypes.node,
};
