/* eslint-disable no-undef */
import axios from "axios";
import { environment } from "../config/environment";

const {
  api: { uri },
} = environment;

const API = axios.create({ baseURL: uri, headers: { "Content-Type": "application/json", Accept: "*/*" } });

export default API;
