import React, { createContext, useContext, useState, useEffect } from "react";

const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
  // Get the theme from local storage on initial load
  const [theme, setTheme] = useState(localStorage.getItem("theme") || "dark");

  const isDarkTheme = theme === "dark" ? true : false;

  const toggleTheme = () => {
    // Toggle dark mode by adding or removing the 'dark' class on the <html> element
    document.documentElement.classList.toggle("dark");
    const newTheme = theme === "light" ? "dark" : "light";
    setTheme(newTheme);
    // Save the theme to local storage
    localStorage.setItem("theme", newTheme);
  };

  // Use useEffect to apply the theme class on initial load
  useEffect(() => {
    document.documentElement.classList.add(theme);
    // Cleanup the class when the component unmounts
    return () => {
      document.documentElement.classList.remove(theme);
    };
  }, [theme]);

  return <ThemeContext.Provider value={{ theme, isDarkTheme, toggleTheme }}>{children}</ThemeContext.Provider>;
};

export const useTheme = () => {
  return useContext(ThemeContext);
};
