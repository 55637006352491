import moment from "moment";
import React, { useEffect, useState } from "react";
import Theme from "./Theme";

const Navbar = () => {
  const [currentTime, setCurrentTime] = useState("");

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(moment().format("DD-MM-YYYY, h:mm A"));
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <header className="bg-secondary sticky top-0 z-[99999]">
      <div className="bg-container py-6 flex justify-between items-center sm:text-2xl text-white font-[500]">
        <div className="flex items-center gap-6">
          <img src="/logo.png" alt="SAHCO" className="max-w-[50%]" />
          <h2 className="hidden sm:block">Flight Information</h2>
        </div>

        <div className="flex items-center gap-4 min-w-fit">
          <p className="min-w-fit">{currentTime}</p>
          <Theme />
        </div>
      </div>
    </header>
  );
};

export default Navbar;
