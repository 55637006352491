import React, { useEffect, useState } from "react";
import FlightCard from "./FlightCard";
import PropTypes from "prop-types";
import { BiSolidPlaneLand, BiSolidPlaneTakeOff } from "react-icons/bi";

const FlightLayout = ({ data, title }) => {
  const [scrolling, setScrolling] = useState(false);

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", () => setScrolling(window.scrollY > 90));
    }
    return () => window.removeEventListener("scroll", () => setScrolling(window.scrollY > 90));
  }, []);

  return (
    <div>
      <div
        className={`${
          scrolling ? "shadow-xl dark:shadow-gray-300 dark:shadow-lg" : ""
        } bg-[#F9E2D2] sticky top-28 text-primary transition-all duration-300 inline-flex gap-2 items-center font-[500] min-w-fit px-5 py-4 rounded-md text-sm md:px-8 md:text-base 2xl:text-xl z-[9999]`}
      >
        {title}

        {title === "Arrivals" ? <BiSolidPlaneLand className="text-xl" /> : <BiSolidPlaneTakeOff className="text-xl" />}
      </div>

      <div className="mt-10 space-y-6">
        {data?.map((flight, i) => (
          <FlightCard key={i} data={flight} />
        ))}
      </div>
    </div>
  );
};

export default FlightLayout;

FlightLayout.propTypes = {
  data: PropTypes.array,
  title: PropTypes.string,
};
